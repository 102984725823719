/* .modelStyle .sc-ion-label-ios-s h1 {
    font-size: var(--font-36);
} */

.modelStyle {
  --min-width: 100%;
  --min-height: 100%;
}

.modelStyle .modal-label {
  margin-bottom: 0;
  font-size: var(--font-36);
  font-weight: bold;
  color: var(--textBlack-color);
}

.modelStyle .modal-label .required {
  font-size: var(--font-14);
}

.modelStyle p {
  color: var(--textGray-color);
  margin-bottom: 0;
}

.modelStyle .modal-header {
  border: 0px solid;
}

.modelStyle .toolbar-background,
.modal-wrapper.sc-ion-modal-ios,
.modal-shadow.sc-ion-modal-ios,
#background-content,
.toolbar-background {
  --background: transparent;
}

.filtermodelStyle .modal-wrapper.ion-overlay-wrapper.sc-ion-modal-md {
  border-radius: 22px;
}

.filtermodelStyle .locationInput .custom-groupInput-label-icon {
  right: 10px;
  top: 46px !important;
}

@media (min-width: 768px) {
  .chat-Message-box {
    --min-width: 100%;
    --min-height: 100%;
  }
}

@media (min-width: 768px) {
  .filtermodelStyle {
    --min-width: 70%;
    --border-radius: 22px;
  }
  .filtermodelStyle .ion-react-wrapper {
    padding: 20px 0px !important;
  }
}
@media (max-width: 768px) {
  .filtermodelStyle .locationInput .custom-groupInput-label-icon {
    top: 42px !important;
  }
}

/* chat box */

.chat-header-toolbar {
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
}

.chat-footer-toolbar {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}

.chat .msg_history {
  position: relative;
  overflow: hidden;
  padding: 0 35px 92px;
  justify-content: flex-end;
  flex-direction: column;
}

.chat .conversation-start {
  position: relative;
  width: 100%;
  margin-bottom: 27px;
  text-align: center;
}

.chat .conversation-start span {
  font-size: 14px;
  display: inline-block;
  color: var(--grey);
}

.chat .msg_history .message {
  font-size: 16px;
  position: relative;
  display: inline-block;
  clear: both;
  margin-bottom: 8px;
  padding: 13px 14px;
  vertical-align: top;
  border-radius: 32px;
}

.chat .msg_history .message img {
  width: 30px;
  height: 30px;
  border-radius: 32px;
}

.chat .msg_history .message .chat-message-time time {
  font-size: 12px;
  color: var(--text-gray);
}

.chat .msg_history .message.sent {
  float: right;
  background-color: var(--chat-card);
  border: 1px solid var(--chip-color);
  align-self: flex-end;
  -webkit-animation-name: slideFromRight;
  animation-name: slideFromRight;
}

.chat .msg_history .message.received {
  float: left;
  background-color: var(--backgroundDark);
  border: 1px solid var(--border-gray);
  align-self: flex-start;
  -webkit-animation-name: slideFromLeft;
  animation-name: slideFromLeft;
}
