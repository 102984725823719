@media (min-width: 768px) {
    .custom-modal {
        --background: white;
        /* margin: 0 auto; */
        /* width: 37.5em; */
        overflow-y: auto;
        padding: 0 20px;
        /* border-radius: 24px; */
    }

    .modal-wrapper.ion-overlay-wrapper.sc-ion-modal-md {
        /* border-radius: 24px; */
    }

    .modal-wrapper.ion-overlay-wrapper.sc-ion-modal-md {
        width: 80%;
    }

}

.custom-modal::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.custom-modal::-webkit-scrollbar-thumb {
    background-color: var(--ion-color-text);
}

.custom-modal::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #F5F5F5;
}