.select-block {
    position: relative;
    display: grid;
    width: 100%;
    /* margin-bottom: 5px; */
}

.input-block+.select-block,
.select-block+.select-block {
    margin-top: 0.9rem;
}

.select-block label {
    font-size: var(--font-14);
    /* margin-bottom: 12px; */
    color: var(--textBlack-color);
    margin: 0;
    padding-top: 10px;
    text-transform: capitalize;
}

.select-block select,
.select-block .css-1fhf3k1-control {

    /* margin-top: 0.48rem;
    appearance: none; */
    /* background-image: url('data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http: //www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>');
            background-repeat: no-repeat;
            background-position-x: 98%;
            background-position-y: 14px; */
    /* background: var(--background-gray);
    border: 1px solid var(--color-line-in-white);
    outline: 0;
    padding: 0 1rem;
    font-size: 1rem;
    color: var(--color-text-title); */
    width: 100%;
    /* margin-top: 0.48rem; */
    border-radius: 50px;
    font-style: italic;
    appearance: none;
    padding: 10px 15px;
    outline: none;
    line-height: 1.5;
    border: 1px solid var(--lightGray);
    font-size: var(--font-16);
    color: var(--textBlack-color);

}

.select-block .css-1fhf3k1-control {
    padding: 5px 15px !important;

}

select.minimal .select-block .css-1fhf3k1-control {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 25px) calc(1em + 5px), calc(100% - 20px) calc(1em + 5px), calc(100% - 2.5em) 0.5em;
    background-size: 6px 6px, 6px 7px, 0px 1.5em;
    background-repeat: no-repeat;

}

/* select.minimal:focus {
    background-image: linear-gradient(45deg, green 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, green 50%),
        linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em,
        calc(100% - 20px) 1em,
        calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
} */


/* .select-block:focus-within::after {
    width: calc(100% - 0.96rem);
    height: 2px;
    content: '';
    background: var(--color-primary-light);
    border: 1px solid var(--color-line-in-white);
    position: absolute;
    left: 0.48rem;
    right: 0.48rem;
    bottom: 0;
} */

.select-block ion-item {
    --background: #fff;
}

select:active {
    border: 1px solid var(--lightGray);
}