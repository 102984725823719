.login-form {
  overflow-y: auto;
  background: var(--backgroundDark);
}

.login-form .card-section,
.footer-content {
  text-align: center;
  width: 728px;
  
  margin: auto;
}

/* .login-form .text-left {
  text-align: left;
} */

.text-center {
  text-align: center;
}

.login-form p {
  /* padding-top: 16px;
  padding-bottom: 32px; */
  font-size: var(--font-16);
}

.login-form .custom-p,
.ant-checkbox-wrapper.custom-p span,
.divider {
  font-size: var(--font-14);
}

.login-form .modify-color {
  cursor: pointer;
}

.login-form .divider {
  color: rgba(5, 37, 50, 0.6);
  font-weight: bolder;
}

.login-form ion-segment-button {
  padding: 13px 16px;
  border-radius: 50px;
  color: var(--skyBlue-color);
}

.login-form ion-segment {
  border-radius: 50px;
  width: 50%;
  border: 1px solid var(--lightGray);
  /* justify-content: space-around; */
  margin: auto;
}

.login-form .segment-button-indicator-background {
  background: var(--skyBlue-color);
}

.login-form .footer-content .footer-rightSection {
  text-align: right;
}

.login-form .footer-content .footer-rightSection .small-text {
  color: var(--gray-color);
}

.custom-reg-content h6 {
  color: var(--textGray-color);
}

.custom-reg-content {
  background: rgba(252, 147, 25, 0.1);
  border-radius: 100px;
  padding: 0 15px;
}

.login-form .footer-content .footer-rightSection .smallfont {
  font-size: var(--font-12);
}

.login-form .footer-content {
  padding: 15px 0;
}

.login-form .login-img {
  text-align: center;
}

ion-slides {
  height: 100%;
}
.slide-background {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-text {
  color: white; /* Adjust text color as needed */
  text-align: center;
}

@media (max-width: 768px) {
  .slide-text {
    padding: 0 20px; /* Add padding to text for better readability on small screens */
  }
  .slide-background {
    height: 80vh; /* Adjust the height for better visibility on smaller screens */
  }
  .slide-text h1{
    font-size: 34px;
  }
}


.header-icon {
  width:6rem;
  /* top: 0px;
    border: 3px solid #aa3559;
    position: absolute;
    border-radius: 50%; */
}

.login-form .login-img img {
  width: 12em;
  height: auto;
  margin: 2em auto;
}

.header {
  font-size: var(--font-28);
  padding-bottom: 16px;
}

.pannel-ReCAPTCHA {
  display: inline-block;
}

.login-form .modify-color,
a {
  color: var(--skyBlue-color);
}

.ant-form-item-explain div {
  text-align: left;
  font-size: var(--font-14);
}

.login-form a {
  font-size: var(--font-14);
  text-decoration: auto;
}

@media (max-width: 768px) {

  .login-form ion-card,
  .footer-content {
    text-align: center;
    width: auto;
    margin: auto 3px;
  }

  .login-form .footer-content .footer-rightSection,
  .footer-content .text-left {
    text-align: center;
  }
}

/* registation */

.registation .header {
  padding-bottom: 0px;
}

.registation span {
  font-size: var(--font-16);
  color: var(--gray-color);
}

.registation span a {
  font-size: var(--font-16);
  text-decoration: auto;
}

.space-bottom {
  padding-bottom: 16px;
}

.registation .ant-form-item-explain div,
.ant-form-item-explain.ant-form-item-explain-error div {
  font-size: var(--font-14);
  text-align: left;
}


/*  */

.helperText {
  font-size: var(--font-14);
  padding-left: 15px;
}



.customeye-btn-modal {
  position: absolute;
  right: 27px;
  top: 2.4em;
  bottom: 17px;
  z-index: 55;
}

.customeye-btn {
  position: absolute;
  right: 22px;
  bottom: 17px;
  z-index: 55;
}

.capitalizes {
  text-transform: capitalize;
}

.customeye-btn-login {
  position: absolute;
  right: 22px;
  bottom: -2px;
  z-index: 55;
}


.custom-content {
  position: absolute;
  right: 30px;
  top: 12px;
  font-size: var(--font-16)
}

.right-content-1 {
  color: var(--skyBlue-color);
}

.right-content-2 {
  color: var(--gray-color);
}

.custom-pointer {
  cursor: pointer;
}

.remove-shadow {
  --box-shadow: none;
  letter-spacing: 0.5px;
}

.login-curve-box {
  width: 184px;
  margin: -8px;
  position: absolute;
  border-radius: 22px;
  height: 149.3px;
  background-color: var(--skyBlue-color);
}

@media (max-width: 767px) {

  .login-form .card-section,
  .footer-content {
    width: auto;
  }

  .login-curve-box {
    margin: -2px
  }
}