/* Core CSS required for Ionic components to work properly */
@import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/react/css/normalize.css";
@import "@ionic/react/css/structure.css";
@import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/react/css/padding.css";
@import "@ionic/react/css/float-elements.css";
@import "@ionic/react/css/text-alignment.css";
@import "@ionic/react/css/text-transformation.css";
@import "@ionic/react/css/flex-utils.css";
@import "@ionic/react/css/display.css";

/* Theme variables */

@import "~bootstrap/dist/css/bootstrap.min.css";
@import "react-bootstrap-typeahead/css/Typeahead.css";
/*important*/

/*important*/
@import "./ionicCss.css";
@import "./navbar.css";
@import "./timeline.css";
@import "./pagination.css";
@import "./modal.css";
@import "./loginregistation.css";
@import "./variables.css";
@import "./quickprofile.css";
@import "./archor.css";
@import "./slideRange.css";

/* temp */

/* @import "./pages.css"; */
/* @import "./fields.css"; */
/* @import "./quickprofile1.css"; */
/* @import "./contractor.css"; */
/* root font setup */

@media (min-width: 768px) {
  :root {
    --font-10: 10px;
    --font-12: 12px;
    --font-13: 13px;
    --font-14: 14px;
    --font-16: 16px;
    --font-18: 18px;
    --font-20: 20px;
    --font-22: 22px;
    --font-24: 24px;
    --font-28: 28px;
    --font-32: 32px;
    --font-36: 36px;
    --font-48: 48px;
  }
}

@media (max-width: 768px) {
  :root {
    --font-10: 10px;
    --font-12: 12px;
    --font-14: 14px;
    --font-16: 14px;
    --font-18: 18px;
    --font-20: 20px;
    --font-22: 22px;
    --font-24: 24px;
    --font-28: 24px;
    --font-32: 28px;
    --font-36: 32px;
    --font-48: 34px;
  }
}

/* end root font setup */

/* font family on seperate tags  */
@font-face {
  font-family: "Montserrat-SemiBold";
  font-style: normal;
  letter-spacing: normal;
  line-height: "1.5";
  font-display: swap; /* or block, swap, fallback, optional */
  src: local("Montserrat-SemiBold"),
    /* url("../assets/font/Montserrat/Montserrat-SemiBold.woff2") format("woff2"),  */
      url("../assets/font/Montserrat/Montserrat-SemiBold.ttf")
      format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  font-style: normal;
  letter-spacing: normal;
  line-height: "1.5";
  font-display: swap; /* or block, swap, fallback, optional */
  src: local("Montserrat-Medium"),
    /* url("../assets/font/Montserrat/Montserrat-Medium.woff2") format("woff2"), */
      url("../assets/font/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

/* font family on seperate tags  */
/* @font-face {
  font-family: "Montserrat-SemiBold";
  font-style: normal;
  line-height: "1.5";
  letter-spacing: normal;
  src: url("../assets/font/Montserrat/Montserrat-SemiBold.ttf");
  src: local('Awesome Font'),
  url('/fonts/awesome-l.woff2') format('woff2'), 
  url('/fonts/awesome-l.woff') format('woff'),
  url('/fonts/awesome-l.ttf') format('truetype'),
  url('/fonts/awesome-l.eot') format('embedded-opentype');
  
} */

/* @font-face {
  font-family: "Montserrat-Medium";
  font-style: normal;
  line-height: "1.5";
  letter-spacing: normal;
  src: url("../assets/font/Montserrat/Montserrat-Medium.ttf");
} */

:root {
  --font-medium: "Montserrat-Medium";
  --font-bold: "Montserrat-SemiBold";
}

* {
  font-family: var(--font-medium);
  font-weight: 500;
  --ion-font-family: var(--font-medium);
  --ion-font-weight: 500;
  /* letter-spacing: -0.16px; */
  font-size: var(--font-16);
}
.custum-gradiend {
  background: linear-gradient(to right, red , var(--skyBlue-color));
			-webkit-text-fill-color: transparent;
			-webkit-background-clip: text;
}
/* end font setup */

/* custom css style */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-bold);
  color: var(--textBlack-color);
}

h1 {
  font-size: var(--font-36);
}

p,
span,
small {
  color: var(--textBlack-color);
}

small {
  font-size: var(--font-14);
}

ion-button.small-btn {
  height: 32px;
}

ion-item {
  --highlight-color-focused: none;
}
ion-button {
  --box-shadow: none !important;
}

ion-thumbnail {
 --size: 20px;
}
[data-tooltip]:before {
  content: attr(data-tooltip);
}

item-inner { border-bottom-color: transparent !important; box-shadow: none !important; }

.scrollit{
  overflow-x: auto;
}
.remove-shadow.small-btn {
  letter-spacing: 0.1px;
}

.orange-outline-btn {
  --ion-color-base: var(--orange-color) !important;
  padding: 3px;
  font-size: 14px;
}

.font-bold {
  font-weight: bold;
}

.main-container {
  width: 100%;
  margin: auto;
  padding: 20px;
}

.header-md::after {
  height: 0;
}

.text-left {
  text-align: left;
}

.text-gray {
  color: var(--textGray-color);
}

.text-orange {
  color: var(--text-orange);
}

.font-36 {
  font-size: var(--font-36);
}

.font-14 {
  font-size: var(--font-14);
}

.text-align-right {
  text-align: end;
}

.ant-layout-header .ant-menu,
.ant-menu,
.ant-menu-item-group-title,
.ant-menu-submenu-popup > .ant-menu,
.ant-layout-header.headerTheme {
  background: var(--backgroundWhite-color);
  color: var(--textBlack-color);
  border: none;
}

.ant-layout-sider,
.ant-layout,
.ant-anchor-ink::before {
  background: var(--backgroundDark);
  color: var(--textBlack-color);
}

.ant-menu-horizontal > .ant-menu-item a {
  color: var(--textBlack-color);
}

a,
a:active,
a:hover,
.ant-menu-item a:hover {
  color: var(--skyBlue-color);
}

ion-header {
  border: none;
  outline: none;
  box-shadow: none;
}

ion-card,
.card {
  border-radius: 22px;
  --ion-background-color: var(--backgroundWhite-color);
  outline: none;
  box-shadow: none;
  margin: auto 0;
  border: 1px solid var(--border-gray);
}

ion-model.custom-model ion-header,
ion-model.custom-model ion-item {
  --ion-background-color: var(--backgroundDark);
}

ion-item.custom-model-class {
  --ion-background-color: var(--backgroundDark);
  --background: var(--backgroundDark);
}

.ant-btn {
  height: 44px;
  border: none;
}

.ant-btn.solid {
  background: var(--skyBlue-color);
  border: 1px solid var(--skyBlue-color);
}

.ant-btn.solid.wid {
  width: 128px;
  margin-left: 1rem;
}

.ant-btn.solid span {
  color: white;
  font-size: var(--font-14);
}

.text-blue,
.text-blue:hover {
  color: var(--skyBlue-color);
}

.text-gray,
.text-gray:hover {
  color: var(--textGray-color);
}

.ant-btn.outline {
  background: transparent;
  color: var(--skyBlue-color);
  border: 1px solid var(--skyBlue-color);
}

.ant-btn.outline ion-icon,
.read-or-hide {
  color: var(--skyBlue-color);
}

.ant-btn.outline span,
.ant-btn.ant-btn-link span {
  color: var(--skyBlue-color);
  font-size: var(--font-14);
}

.space-right {
  margin-right: 10px;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.locationSearch {
  position: absolute;
  background: var(--backgroundWhite-color);
  z-index: 4029;
  list-style-type: none;
  padding: 15px;
  overflow-y: auto;
  width: 100%;
  border-radius: 5px;
  max-height: 200px;
  bottom: 28px;
}

.crm-filter ion-col {
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

td ion-icon {
  width: 20px;
  cursor: pointer;
  height: 20px;
  border: 1.5px solid var(--border-gray);
  position: relative;
  padding: 1px;
  margin-right: 3px;
}

td ion-button {
  height: auto;
  --padding-end: auto;
  --padding-start: 0;
}

.upload-img img {
  position: absolute;
  margin: 1rem auto;
}

.orange-color {
  text-align: center;
  background: var(--orange-gradiend);
  color: white;
}

.custom-flex-between {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-device-width: 2491px) {
  ion-content,
  ion-header {
    --padding-end: 350px;
    --padding-start: 350px;
  }
  ion-header.custom-header {
    padding-left: 350px;
    padding-right: 350px;
  }
  .modelheader {
    width: 80%;
    margin: auto;
  }
}
