/* desktop */

ion-header.custom-header {
  --ion-background-color: var(--backgroundWhite-color);
  background-color: var(--backgroundWhite-color);
  z-index: 20001;
}

/* modile menu */

.mobile-menu-header {
  padding: 20px 0 0 10px;
}

.mobile-menu-header img{
  width: 65px;
  border-radius: 32px;
  height: 65px;
  object-fit: cover;
  object-position: bottom;
}

.top-Nav li img {
  width: 40px;
  border-radius: 32px;
  height: 40px;
  object-fit: cover;
  object-position: bottom;
}

ion-menu-button {
  font-size: large;
}

ion-menu-button.md.button.in-toolbar.ion-activatable.ion-focusable.hydrated {
  font-size: 22px;
}

.nav-web {
  justify-content: space-between;
  display: flex;
}

.top-Nav a {
  text-decoration: none;
}

.top-Nav ul {
  /* background: darkorange; */
  align-items: center;
  list-style: none;
  padding-left: 0;
}

.top-Nav ul.nav-ul {
  display: flex;
  margin: 0;
}

.top-Nav li {
  /* color: #fff;
  background: darkorange; */
  padding: 0 10px;
  display: block;
  float: left;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
}

.top-Nav li a {
  color: var(--textBlack-color);
  font-size: var(--font-16);
}

.top-Nav li:hover {
  cursor: pointer;
}

.top-Nav ul li ul.dropdown.profile {
  right: 0;
  left: auto;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.top-Nav ul li ul.dropdown {
  background: var(--backgroundWhite-color);
  visibility: hidden;
  opacity: 0;
  width: max-content;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 0rem;
  padding: 15px;
  padding-top: 1rem;
  border-radius: 10px;
  left: 0;
  display: none;
}

.top-Nav ul li ul.dropdown li {
  padding: 10px 0;
  border-bottom: 0.01px solid var(--lightGray);
}

.top-Nav ul li:hover > ul,
.top-Nav ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
  z-index: 20003;
}

.top-Nav ul li ul li {
  clear: both;
  width: 100%;
}

.menuBar {
  width: 85%;
  margin: auto;
  display: flex;
  padding: 10px 0px;
  /* border-bottom: solid 1px #e8e8e8; */
  overflow: auto;
  /* box-shadow: 0 0 30px #f3f1f1; */
}

.logo {
  width: 150px;
  /* float: left; */
  padding-top: 6px;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}

.menuCon {
  width: calc(100% - 150px);
  /* float: left;
  padding: 12px 0px; */
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: left;
}

.menuCon .rightMenu {
  float: right;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: var(--skyBlue-color);
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: var(--skyBlue-color);
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu > span {
  display: block;
}

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo a {
    padding: 10px 20px;
  }
}
