@import url("https://fonts.googleapis.com/css?family=Poppins:800,700,var(--hirifi-com-poppins-black-44-font-weight),var(--hirifi-com-poppins-black-62-font-weight),var(--hirifi-com-poppins-black-22-font-weight)|Noto+Sans:var(--hirifi-com-noto-sans-display-regular-16-font-weight),var(--hirifi-com-noto-sans-bold-12-upper-font-weight),700,var(--hirifi-com-noto-sans-display-regular-25-font-weight),var(--hirifi-com-noto-sans-bold-13-font-weight),var(--hirifi-com-noto-sans-display-regular-14-font-weight),var(--hirifi-com-noto-sans-display-regular-22-font-weight),var(--hirifi-com-noto-sans-bold-14-font-weight),400,var(--hirifi-com-noto-sans-display-regular-13-font-weight),var(--hirifi-com-noto-sans-bold-14-upper-font-weight)|Roboto:400");

.header-logo {
  z-index: 99;
  max-width: 8rem;
  position: absolute;
  top: 3rem;
  left: 3rem;
}

.whatsapp-button {
  background: #47c756 !important;
  color: white !important;
  width: 60%;
  border-radius: 3rem !important;
}

.carousel-item {
  height: 689px;
  z-index: 4;
}

.carousel-item img {
  height: 100%;
  object-fit: cover;
}

.slide-text h1 {
  position: relative;
  text-shadow: 0px 4px 2px #00000040;
  font-family: "Poppins", Helvetica;
  font-weight: 700;
  white-space: wrap;
  color: var(--hirificomnero);
  font-size: 62px;
  text-align: center;
  letter-spacing: 0;
  line-height: 74.4px;
}

.video-section {
  max-width: 35%;
}

@media (max-width: 768px) {
  .video-section {
    max-width: 80%;
  }
  
}

.slide-text p {
  position: relative;
  text-shadow: 0px 4px 2px #00000040;
  font-family: var(--hirifi-com-noto-sans-display-regular-25-font-family);
  font-weight: var(--hirifi-com-noto-sans-display-regular-25-font-weight);
  color: var(--hirificomnero);
  font-size: var(--hirifi-com-noto-sans-display-regular-25-font-size);
  text-align: center;
  letter-spacing: var(--hirifi-com-noto-sans-display-regular-25-letter-spacing);
  line-height: var(--hirifi-com-noto-sans-display-regular-25-line-height);
  white-space: wrap;
  font-style: var(--hirifi-com-noto-sans-display-regular-25-font-style);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #fff;
}

.slide-text .btn {
  display: inline-flex;
  max-width: 130.72px;
  min-height: 56px;
  align-items: center;
  justify-content: center;
  padding: 18.5px 40px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--hirificomgolden-sand);
  border-radius: 48px;
  font-family: var(--hirifi-com-noto-sans-bold-14-upper-font-family);
  font-weight: var(--hirifi-com-noto-sans-bold-14-upper-font-weight);
  color: var(--hirificomblack);
  font-size: var(--hirifi-com-noto-sans-bold-14-upper-font-size);
  text-align: center;
  letter-spacing: var(--hirifi-com-noto-sans-bold-14-upper-letter-spacing);
  line-height: var(--hirifi-com-noto-sans-bold-14-upper-line-height);
  font-style: var(--hirifi-com-noto-sans-bold-14-upper-font-style);
}

.section-heading {
  font-family: "Poppins", Helvetica;
  font-weight: 700;
  color: var(--hirificomblack);
  font-size: 44px;
  text-align: center;
  letter-spacing: 0;
  line-height: 1.2;
}

.section-heading-1 {
  font-family: "Poppins", Helvetica;
  font-weight: 700;
  color: var(--hirificomblack);
  font-size: xx-large;
  text-align: center;
  letter-spacing: 0;
  line-height: 1.2;
}

.header .separator-2 {
  border-color: var(--hirificomtacha);
  position: relative;
  width: 50px;
  height: 2px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.header .separator-2 {
  border-color: var(--hirificomtacha);
  position: relative;
  width: 50px;
  height: 2px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.header .separator-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 531px 0px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.header .separator {
  position: relative;
  width: 50px;
  height: 2px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: #e2e2e2;
}

.text-yellow {
  color: var(--hirificomtacha);
}

.bg-yellow {
  background-color: var(--hirificomtacha);
}

.header .separator1 {
  position: relative;
  width: 50px;
  height: 2px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: var(--hirificomtacha);
}

ul li {
  /* list-style-type: none; */
  padding: 5px 0;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
}

p {
  font-family: "Poppins", Helvetica;
  font-size: 16px;
}

.profile-button {
  display: inline-flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  padding: 11.5px 25px;
  border-radius: 48px;
  border: 1px solid;
  border-color: var(--hirificomyellow-metal);
  font-family: var(--hirifi-com-noto-sans-bold-12-upper-font-family);
  font-weight: var(--hirifi-com-noto-sans-bold-12-upper-font-weight);
  color: var(--hirificomyellow-metal);
  font-size: var(--hirifi-com-noto-sans-bold-12-upper-font-size);
  text-align: center;
  letter-spacing: var(--hirifi-com-noto-sans-bold-12-upper-letter-spacing);
  line-height: var(--hirifi-com-noto-sans-bold-12-upper-line-height);
  font-style: var(--hirifi-com-noto-sans-bold-12-upper-font-style);
}

.whatsapp-button {
  display: inline-flex;
  max-width: 401.28px;
  min-height: 56px;
  padding: 19px 40px 19px 32px;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 48px;
  background: var(--hirifi-com-fiord, #455a64);
}

#map-container {
  position: relative;
  width: 100%;
  height: 100vh;
  /* Set height to full viewport height */
  overflow: hidden;
}

#map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.img-section {
  object-fit: cover;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .header .separator-wrapper {
    padding: 0;
  }

  .carousel-item {
    height: 320px;
  }

  .header-logo {
    max-width: 4rem;
    top: 1rem;
    left: 1rem;
  }

  .carousel-item h1 {
    font-size: 25px;
    line-height: normal;
  }

  .carousel-item p {
    white-space: pre-wrap;
    font-size: medium;
    line-height: normal;
  }

  .carousel-item .btn {
    min-height: auto;
    padding: 8px;
  }
  .section-heading {
    font-size: 30px;
    line-height: 1.2;
    padding: 0 3px;
  }
  .ul li,
  p {
    font-size: 14px;
  }
}

/* Style the header with a grey background and some padding */
.header1 {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  background: transparent;
  overflow: hidden;
  /* background-color: #f1f1f100; */
  padding: 20px 10px;
}

/* Style the header links */
.header1 a {
  float: left;
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
  text-shadow: 0px 4px 2px #00000040;
  font-family: "Poppins", Helvetica;
  font-weight: 700;
}

.header1 .logo:hover {
  border-bottom: none !important;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.header1 a.logo {
  font-size: 25px;
  font-weight: bold;
}

.header1 .logo img {
  max-width: 100%;
}

/* Change the background color on mouse-over */
.header1 a:hover {
  background-color: transparent !important;
  color: white;
  border-bottom: white 2px solid;
  transition: 0.5s;
}

/* Style the active/current link*/
.header1 a.active {
  background-color: dodgerblue;
  color: white;
}

/* Float the link section to the right */
.header-right {
  float: right;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 500px) {
  .header1 .logo img {
    max-width: 60%;
  }

  .header1 a {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
    display: flex;
    justify-content: center;
    /* display: none; */
  }
  .header1 {
    padding: 0;
  }
}

/* Dropdown Button */
.dropbtn {
  background-color: transparent;
  color: black;
  padding: 16px;
  font-size: 26px;
  border: none;
  cursor: pointer;
}

.dropbtn .navbar-toggle-icon {
  font-size: 23px;
  color: white;
}
/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: transparent;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 888;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.active {display:block;}
