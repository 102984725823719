.pagination {
    justify-content: center;
}

/* ul {
    list-style: none;
    padding: 0;
} */

.Jobs ul.pagination li a {
    color: var(--textGray-color) !important;
    background: var(--backgroundWhite-color);
    border-radius: 22px;
}

.Jobs ul.pagination li {
    border: 0px solid var(--lightGray);
    background: var(--backgroundWhite-color);
    border-radius: 22px;
}


.Jobs ul.pagination li.active,
.Jobs ul.pagination li.active a {
    background: var(--chip-color) !important;
    color: var(--text-orange) !important;
}



ul.pagination li {
    display: inline-block;
    width: 30px;
    border: 1px solid var(--lightGray);
    display: flex;
    justify-content: center;
    font-size: 25px;
}

ul.pagination li a {
    text-decoration: none;
    color: var(--skyBlue-color);
    font-size: 20px;
}

ul.pagination li.active a {
    color: var(--textWhite-color);
}

ul.pagination li.active {
    background-color: var(--skyBlue-color);
}

ul.pagination li a:hover,
ul.pagination li a.active {
    color: blue;
}

.page-selection {
    width: 48px;
    height: 30px;
    color: #337ab7;
}

.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}