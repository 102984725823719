ion-button.icon-btn button.button-native {
  --width: 50px !important;
  --height: 50px !important;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

ion-button.custom-close {
  --background: var(--backgroundWhite-color) !important;
  --border-color: var(--lightGray) !important;
}

ion-item {
  --padding-start: 0;
}

.btn.custom-outline-round-btn {
  border: 1px solid var(--skyBlue-color);
  color: var(--skyBlue-color);
  border-radius: 50%;
  height: 42px;
  width: 42px;
  padding: 10px;
}

.nav-ul li a {
  font-family: 'Montserrat-Medium';
}

.sort-group {
  width: 13em;
  height: 12px;
  /* padding: 7px 0; */
}

.sort-group .input-group-text {
  border: solid 1px var(--lightGray);
  color: var(--gray);
  background: var(--backgroundWhite-color);
  border-radius: 22px;
}

.ion-sort-input {
  top: -14px;
}

.filter-option .range-filter ion-range {
  top: -6px;
}

.ion-sort-input .input-group-text {
  width: fit-content;
}

.ion-sort-input ion-select {
  width: fit-content;
}

.sort-input {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.header-content-flex {
  display: flex;
  justify-content: space-between;
}

.sort-input-section {
  display: grid;
  grid-auto-flow: column;
}

.contractor.Jobs .sort-input-section {
  display: inline-grid;
}

.contractor.Jobs .sort-input-section .search-input {
  /* width: calc(55 rem - 50 px) !important; */
  width: 55rem;
}

#controlled-tab-custom .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--text-orange);
  background-color: transparent;
  border-bottom-width: 1px;
  border-color: transparent transparent var(--text-orange) !important;
}

#controlled-tab-custom#contractor .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--skyBlue-color);
  border-color: transparent transparent var(--skyBlue-color) !important;
}

.nav-tabs .nav-link {
  color: var(--textBlack-color);
  border-color: transparent transparent transparent !important;
  border-bottom-width: 1px;
  font-size: var(--font-14);
}

.react-tags__search-input {
  width: 100% !important;
}

ion-input input.native-input,
ion-select,
.react-tags .react-tags__search-input,
select,
.locationInput input,
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"],
.rbt-input-multi.form-control,
.rbt-input-main.form-control,
ion-textarea,
.react-datepicker__input-container input {
  border: solid 1px var(--lightGray) !important;
  background: var(--backgroundWhite-color);
  color: var(--textBlack-color);
  width: 100%;
  border-radius: 22px !important;
  padding: 8px 12px !important;
  font-size: var(--font-16);
}

input.count-box {
  border: 1px solid solid 1px var(--lightGray);
  background: var(--backgroundWhite-color);
  text-align: center;
  width: 6em;
}

ion-textarea {
  height: auto;
}

.content-flex {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.right-btn {
  float: right;
}

ion-input input {
  font-size: var(--font-16) !important;
  font-family: var(--font-medium) !important;
}

select:focus-visible {
  outline: none;
}

ion-label.label-stacked.sc-ion-label-md-h {
  margin-bottom: 14px;
  padding-left: 14px;
  position: relative;
  /* display: flex; */
  transform: translateY(50%) scale(1);
  font-family: var(--font-medium);
  font-size: var(--font-16);
}

.custome-item-color {
  --background: transparent;
}

.required,
.red {
  color: var(--textRed-color) !important;
}
.input-div {
  padding: 13px 0;
}

.input-div ion-label,
.input-div label,
.custom-lable ion-label,
.locationInput ion-label {
  color: var(--textBlack-color) !important ;
}

ion-item {
  z-index: 0;
  --highlight-color-focused: transform;
  outline: none !important;
}

input:focus {
  outline: none !important;
}

item-inner {
  border-bottom-color: transparent !important;
  box-shadow: none !important;
}

.errorMessage {
  color: var(--textRed-color);
  font-size: var(--font-14);
  padding-left: 15px;
  text-transform: capitalize;
}

ion-button {
  height: 44px;
}

.custom-table-icon-btn ion-icon {
  font-size: var(--font-20);
}

.social .socialicon {
  border: solid 1px var(--border-gray);
  padding: 10px;
  border-radius: 50px;
  margin-right: 5px;
}

.social .childicon {
  position: relative;
  margin-left: -18px;
  border-radius: 50px;
  margin-bottom: -18px;
  padding: 0;
  background: var(--background-img-gray);
  color: var(--skyBlue-color);
}

hr.dashed {
  border-top: 3px dashed var(--border-gray);
}

.swiper-pagination-bullet-active {
  background: var(--orange-color);
}

.swiper-pagination-bullet {
  background: var(--orange-color, 0.4);
}

ion-range {
  z-index: 0;
  --bar-background: #15d43552;
  --bar-background-active: var(--green-color);
  --bar-height: 4px;
  --knob-background: var(--green-color);
  --pin-color: var(--green-color);
  --knob-size: 20px;
}

ion-range.skillRange,
ion-range.danger {
  --bar-background: var(--lighttextRed-color);
  --bar-background-active: var(--textRed-color);
  --knob-background: var(--textRed-color);
  --pin-color: var(--textRed-color);
}

ion-range.warning {
  --bar-background: var(--btnlight-yellow);
  --bar-background-active: var(--btn-yellow);
  --knob-background: var(--btn-yellow);
  --pin-color: var(--btn-yellow);
}

/* .toggle-inner {
    border: 2px solid #15d43552 !important;
} */

ion-segment.reference-segment .correct {
  color: var(--skyBlue-color);
}

ion-segment.reference-segment .wroung {
  color: var(--textRed-color);
}

ion-toggle .toggle-inner {
  border: 1px solid #000000 !important;
}

ion-toggle {
  --background: #e0e0e0;
  --background-checked: var(--lightgreen-color);
  --handle-background: #aaa;
  --handle-background-checked: var(--green-color);
}

/* profile img */

.profile-img img,
.profile-img-com img {
  width: 158px !important;
  height: 158px;
  border-radius: 50%;
  border: 5px solid var(--backgroundDark);
  background: var(--background-img-gray);
}

.profile-img {
  /* margin-top: -5.5rem; */
  margin-top: 1.2rem;
}

.profile-img-com label,
.profile-img-com label:hover {
  width: 44px;
  height: 44px;
  left: 100px;
  bottom: 32px;
  margin-bottom: -6rem;
  margin-left: -3rem;
  background: var(--orange-color);
  color: #fff;
  border-radius: 50px;
  padding: 10px;
  border: 2px solid var(--orange-color);
}

.profile-img label,
.profile-img label:hover {
  top: 3rem;
  height: 20px;
  right: 3rem;
  position: relative;
  width: 44px;
  height: 44px;
  background: var(--skyBlue-color);
  color: #fff;
  border-radius: 50px;
  padding: 7px;
  border: 2px solid var(--backgroundDark);
}

.profile-img ion-icon {
  font-size: 25px;
  color: #fff;
}

/* custom select box */

.currencyLabel {
  position: absolute;
  right: 20px;
  max-width: 94px;
  top: 11px;
}

.currencyLabel select {
  border: 0px solid !important;
  height: 20px;
  padding: 0 10px !important;
  color: var(--skyBlue-color);
}

hr:not([size]) {
  background: var(--lightGray);
}

.custom-groupInput {
  position: absolute;
  /* top: 50%; */
  bottom: 2%;
  right: 23px;
  max-width: 110px;
  width: auto;
  z-index: 999;
}

.custom-groupInput-label {
  position: absolute;
  /* top: 50%; */
  bottom: 10%;
  color: var(--textGray-color);
  right: 34px;
  max-width: 110px;
  width: auto;
  z-index: 999;
}

.text-pointer {
  cursor: pointer;
}

.range-filter {
  width: 350px;
}

/* .range-filter ion-range {
  padding-top: 12px;
} */

.custom-groupInput-label-icon {
  position: absolute;
  top: 46px;
  /* bottom: 10%; */
  color: var(--textGray-color);
  right: 12px;
  max-width: 110px;
  width: 35px;
  height: 35px;
  padding: 5px 7px;
  z-index: 999;
  background: #fc931929;
  border-radius: 22px;
  cursor: pointer;
}

.text-right {
  float: right;
  margin-right: 25px;
  margin-top: 10px;
}

.custom-groupInput select {
  border: 0px solid !important;
}

ion-button.next-btn {
  color: #fff;
  --background: var(--orange-color);
}

ion-button.next-btn-outline {
  color: var(--orange-color);
  --background: var(--backgroundWhite-color);
  border-radius: 22px;
  border: 2px solid var(--orange-color);
}

ion-item ion-icon {
  font-size: var(--font-20);
}

.lowercase {
  text-transform: lowercase;
}

.hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  height: 0.2px;
  border-top: 0.2px solid var(--lightGray);
}

.custom-circle-btn {
  width: 44px;
  height: 44px;
  margin: auto;
  font-size: var(--font-10);
}

.custom-header-space {
  padding-top: 20px;
  padding-bottom: 20px;
}

.custom-modal-alignment {
  margin: 15px 6px !important;
}

/* ckeditor */

.ck.ck-editor {
  width: 100% !important;
}

/* read-more or less */

span.read-or-hide {
  color: var(--skyBlue-color) !important;
  cursor: pointer;
}

/* experience */

.highlightContent {
  color: var(--orange-color);
}

.content-gray {
  color: var(--textGray-color);
}

.custom-label {
  font-size: var(--font-16);
}

.locationInput {
  display: grid;
}

/* form custom upload files */
.custom-file {
  border-style: dotted;
  border-radius: 22px;
  border-color: var(--lightGray);
  padding: 20px;
}

.custom-upload {
  width: 100%;
  text-align: center;
  margin-top: -25px;
}

.custom-upload-label {
  width: 100%;
}

.custom-upload-text {
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
}

.custom-text,
ion-item.custom-text:hover {
  color: var(--skyBlue-color);
}

.custom-upload-text h6 {
  font-size: var(--font-14);
}

.custom-upload-text h4 {
  font-size: var(--font-16);
}

.custom-upload-text p {
  font-size: var(--font-12);
}

.right-content .h1-pd {
  padding-bottom: 18px;
  font-size: var(--font-28);
}

.pad-style {
  padding: 0px 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

ul.ulelement {
  list-style-type: none;
  padding-left: 0;
}

.custom-style-button {
  --margin: 0;
  margin-inline-start: 10px;
  padding: 0px;
  font-size: 14px;
  --padding-start: 15px;
  --padding-end: 15px;
}

/* public contractor */

hr.dot {
  border-top: 2px dotted var(--textBlack-color);
}

hr {
  border-top-style: groove;
  border-top-width: 0.5px;
  border-top-color: var(--border-gray);
}

.h1-space {
  padding-top: 20px;
  padding-bottom: 10px;
}

.text-in-line {
  line-height: 1.5em;
  height: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.main-section {
  /* border-right-style: groove; */
  margin-right: 20px;
  max-height: 80px;
  /* border-right-width: 1px;
    border-color: var(--lightGray); */
}

.main-section.vertical-line {
  border-right: 1px solid var(--lightGray);
}

.vertical-line {
  border-right: 1px solid var(--lightGray);
}

.main-section label {
  font-size: var(--font-14);
  text-transform: uppercase;
  padding-bottom: 15px;
}

.main-section ion-icon {
  font-size: 18px;
  padding-right: 10px;
}

.main-section .body-content-section {
  display: flex;
  font-family: var(--font-bold);
}

.main-section .body-content-section p {
  margin-bottom: 0em;
}

.main-section .body-content-section ion-icon {
  font-size: var(--font-20);
  color: var(--orange-color);
}

.main-section .small-text {
  font-size: var(--font-14);
}

/* dekiaa slider */

.backBtn {
  left: 0;
}

.nextBtn {
  right: 0;
}

.deikaa .content-header {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.deikaa {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.deikaa .content-header h3 {
  font-family: var(--font-bold);
  font-size: var(--font-22);
}

.deikaa .content-header h3 ion-label {
  font-size: var(--font-12);
}

.deikaaSlides .swiper-pagination {
  top: auto;
  bottom: 10px;
}

.left-space-15 {
  padding-left: 20px;
}

.chip-font {
  font-size: var(--font-12) !important;
  background: transparent !important;
  letter-spacing: 0.5px;
  font-family: var(--font-medium) !important;
}

.custom-ticket-chip {
  /* height: 24px; */
  margin-left: 0;
}

.deikaa-chip-orange,
.chip-font.orange {
  background: var(--chip-color);
  color: var(--orange-color) !important;
}

.deikaa-chip-yellow,
.chip-font.yellow {
  background: var(--background-yellow);
  color: var(--yellow-color) !important;
}

.deikaa-chip-green,
.chip-font.green {
  background: var(--background-green);
  color: var(--green-color) !important;
}

.deikaa-chip-black,
.chip-font.black {
  background: var(--background-black);
  color: var(--textBlack-color) !important;
}

.textAlign-right {
  position: absolute;
  right: 0;
  top: 0px;
}
.removePadding {
  padding: 0px;
}

.deikaa-chip-danger,
.chip-font.danger {
  background: var(--background-danger);
  color: var(--textRed-color) !important;
}

.custom-deikaa-modal {
  max-width: 59%;
  margin: auto;
}

.custom-deikaa-modal h1 {
  font-size: var(--font-32);
}

.custom-deikaa-modal p,
.custom-deikaa-modal span,
.custom-deikaa-modal ion-icon {
  font-size: var(--font-13);
}

.deikaa-details {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background: rgba(21, 212, 53, 0.1);
}

.deikaa-details span {
  font-size: var(--font-18);
}

.deikaa-details p {
  padding-bottom: 10px;
  font-size: var(--font-14);
}

.deikaa-details a {
  color: var(--green-color);
}

.custom-deikaa-modal img {
  max-width: 240px;
}

.cut-color {
  --background: rgb(231 251 234);
}

.deikaa-font-12,
.deikaa-font-12 a {
  font-size: var(--font-12) !important;
}

.model-bg {
  --ion-background-color: var(--backgroundWhite-color);
}

.deikaa .deikaa-slider-section {
  margin: auto;
  width: 90%;
  position: relative;
}

.deikaa .deikaa-slide-btn {
  width: 24px;
  border-radius: 50%;
  height: 24px;
  padding: 2px;
  position: absolute;
  top: 3.5rem;
}

.custom-alert-review {
  --min-width: 33em;
}

/* media query for mobile version */
@media (min-width: 320px) and (max-width: 575px) {
  .custom-modal-alignment {
    margin: 2px !important;
  }

  .custom-alert-review {
    --min-width: auto;
  }

  .main-section.vertical-line {
    border-right: 0px solid var(--lightGray);
    border-bottom: 1px solid var(--lightGray);
  }

  .vertical-line {
    border-right: 0px solid var(--lightGray);
    border-bottom: 1px solid var(--lightGray);
  }

  .modelStyle .modal-label {
    font-size: 18px !important;
  }

  .closeoutline-mobile-version .button-native {
    width: 80% !important;
    height: 80% !important;
  }
}

@media (max-width: 678px) {
  .custom-deikaa-modal {
    max-width: auto;
  }

  .range-filter {
    width: 100%;
  }
}

/* side nav bar */

.side-groupNav {
  padding: 30px;
  padding-top: 50px;
}

.side-nav-header .label,
.side-nav-subheader .label {
  color: var(--textGray-color);
}

.side-groupNav .bottom-line {
  border-bottom-style: inset;
  border-bottom-width: 1px;
  border-bottom-color: var(--lightGray);
}

.side-nav-header:hover .label,
.side-nav-subheader:hover .label,
.side-nav-header:active .label,
.side-nav-subheader.active .label {
  color: var(--skyBlue-color);
}

.side-nav-header .label {
  font-family: var(--font-bold) !important;
  color: var(--textBlack-color) !important;
}

.side-nav-subheader .label {
  padding: 0 15px;
}

/* cover image */

#tm-container {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(22, 22, 22, 0.5);
  top: 0;
  left: 0;
  display: flex;
  z-index: 40008;
}

.tm-reveal-modal {
  margin: 0 auto;
  position: relative;
  z-index: 41;
  padding: 30px;
}

ion-content {
  z-index: 11;
}

.text-white {
  color: var(--backgroundWhite-color);
}

/* deikaa token */

#deikaa-header-pannel {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 22px;
  color: #fff;
  background-image: linear-gradient(90deg, rgba(80,210,137,1) 0%, rgba(227,203,86,1) 46%, rgba(227,203,86,1) 100%);
}

#deikaa-header-pannel .token {
  display: flex;
  justify-content: space-between;
}

#deikaa-header-pannel .text-white h3,
#deikaa-header-pannel h1,
#deikaa-header-pannel p,
#deikaa-header-pannel .token p small {
  color: #fff !important;
}

#deikaa-header-pannel .token .token-content h4 {
  color: var(--orange-color);
}

.reward-btn {
  font-size: var(--font-14);
  color: var(--skyBlue-color);
  background: transparent;
}

#deikaa-header-pannel .token .token-content .reward-btn ion-icon {
  font-size: var(--font-14) !important;
  margin: -2px;
  margin-left: 2px;
}

.collapse-option {
  position: relative;

  font-size: 24px;
}

#deikaa-header-pannel .token .token-content {
  width: 148px;
  padding: 10px;
  display: inline-block;
  background: var(--backgroundWhite-color);
  border-radius: 22px;
  position: relative;
  z-index: 0;
}

#deikaa-header-pannel .token .token-content:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
  border-radius: 15px;
  background: linear-gradient(
    to right,
    var(--orange-color),
    rgba(23, 197, 157, 1) 38%,
    rgba(24, 191, 205, 1) 72%
  );
  -webkit-mask: linear-gradient(var(--backgroundWhite-color) 0 0) content-box,
    linear-gradient(var(--backgroundWhite-color) 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

#deikaa-header-pannel .token .token-content ion-icon {
  font-size: var(--font-18) !important;
}

/* rewards page */

.rewards-cards .reward ion-label,
.reward-content h3 {
  color: var(--textBlack-color) !important;
}

.rewards-cards .reward ion-label p,
.reward-content p {
  color: var(--gray) !important;
}

.rewards-cards .badge {
  font-size: var(--font-36);
  padding: 0;
  color: var(--textBlack-color);
  background: transparent;
}

.reward-token {
  padding: 24px;
  border-radius: 22px;
  background: var(--backgroundWhite-color);
}

.rewards-cards .reward .ion-icon {
  font-size: var(--font-28);
}

.orange {
  color: var(--orange-color);
}

.border-orange-gradient {
  background: linear-gradient(to right, #fc9319, rgba(255, 255, 255, 0.15));
  padding: 3px;
  z-index: 22;
}

.text-gray {
  color: var(--gray);
}

.sub-deikaa-subject,
p.sub-deikaa-subject a {
  color: var(--backgroundWhite-color);
}

/* model */

.custom-header-model {
  position: sticky;
  top: 0;
  z-index: 555;
  background-color: var(--backgroundWhite-color);
  border-radius: 22px;
  margin: 20px 0;
}

.custom-ion-item {
  --background: var(--backgroundWhite-color);
}

/* feedback */

.feedback-form .feedback-img img {
  width: 280px;
  margin: 2em auto;
}

.socialicon-pacement {
  position: absolute;
  top: 46px;
  z-index: 2;
  left: 8px;
}

.customInput ion-input input {
  padding-left: 35px !important;
}

.social-section {
  display: flex;
  justify-content: space-between;
}

/* refer */

.color-box.orange {
  background-color: var(--orange-color);
}

.color-box.blue {
  background-color: var(--skyBlue-color);
}

.color-box {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 24px;
}

.card-position {
  margin: 8px;
}

.card-img-position {
  position: absolute;
  right: 2rem;
  top: 3rem;
}

.refer .curve {
  width: 120px;
  height: 120px;
  margin: -58px -30px;
  padding: 57px 53px 18px 40px;
  border-bottom-right-radius: 90px;
}

.refer .curve-2 {
  padding-right: 20px;
  padding-left: 7rem;
}

.refer .curve.blue {
  background: var(--skyBlue-color);
  color: #fff;
  font-size: var(--font-32);
}

.refer .curve.green {
  background: var(--green-color);
  color: #fff;
  font-size: var(--font-32);
}

.refer .curve.orange {
  background: var(--orange-color);
  color: #fff;
  font-size: var(--font-32);
}

.refer .code-copy {
  width: 188px;
  height: 102px;
  padding: 13px 14px 19px 18px;
  border-radius: 12px;
  background-color: rgba(5, 37, 50, 0.1);
}

.copyclip-btn {
  background: transparent;
  color: #fff;
}

/* job screen */

.nojob-section {
  margin: 3rem auto;
  text-align: center;
}

.nojob-section h1 {
  font-size: var(--font-36);
}

.nojob-section p {
  font-size: var(--font-16);
  color: var(--textGray-color);
  max-width: 60%;
  margin: auto;
  padding: 1rem;
}

.nojob-section p a {
  color: var(--orange-color);
}

.job-segment {
  width: 16rem;
  border: 1px solid var(--border-gray);
}

.Jobs .header-section,
.Jobs .flex-section,
.screen-header {
  display: flex;
  justify-content: space-between;
}

.Jobs ion-card {
  border: 1px solid var(--border-gray);
}
.custom-bg-transfram input {
  background: transparent !important;
}

.Jobs .job-segment {
  --background: var(--backgroundWhite-color);
  margin: 4px;
  max-height: 3rem;
  border-radius: 22px;
}

.job-segment-form {
  --background: var(--backgroundWhite-color);
  width: 100%;
  border-radius: 22px;
  border: 1px solid var(--border-gray);
}

.Jobs .segment-button-checked {
  background: var(--chip-color);
  color: var(--orange-color);
  border: 1px solid var(--orange-color);
  padding: 5px;
  border-radius: 22px;
  --indicator-color: none;
  --indicator-box-shadow: none;
}

.ul-element {
  list-style: none;
  margin: 0;
}

.ul-element li {
  padding: 6px;
}

.align-label {
  display: inline-flex;
}

.orange-icon {
  color: var(--orange-color);
  font-size: var(--font-20);
}

.com-custom-grid {
  display: inline-grid;
  grid-template-columns: repeat(5, auto);
  grid-auto-rows: auto;
  padding: 15px 0;
}

.com-custom-grid .com-col-section {
  padding-right: 15px;
  padding-left: 15px;
  border-right-style: inset;
  border-right-color: var(--lightGray);
  border-right-width: 1.8px;
}

.com-custom-grid .com-col-section.remove-p {
  padding-left: 0px;
}

.com-header-details h2 {
  font-size: var(--font-28);
}

.com-col-section div small {
  padding-left: 5px;
  text-transform: uppercase;
  color: var(--textGray-color);
  font-family: var(--font-bold);
}

.com-col-section label {
  padding-top: 8px;
  color: var(--textBlack-color);
}

.search-input {
  padding: 7px;
  position: relative;
}

.search-input .native-input.sc-ion-input-md {
  background: var(--backgroundWhite-color);
}

.search-input .search-icon {
  position: absolute;
  top: 16px;
  z-index: 5;
  right: 20px;
}

.filter-icon {
  margin: 5px 7px;
  background: var(--backgroundWhite-color);
  height: 45px;
  width: 45px;
  padding: 9px;
  border-radius: 22px;
  border: 1px solid var(--lightGray);
  cursor: pointer;
}

.Jobs .progress {
  height: 0.2rem;
  margin: 10px;
  margin-top: 0;
}

.progress-bar {
  background-color: rgba(21, 212, 53, 1);
}

.heighlite-tag {
  position: absolute;
  z-index: 4;
  left: 20px;
  top: 0px;
  background: var(--textRed-color);
  padding: 0px 10px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.heighlite-tag small {
  color: #fff;
}

.review-chip {
  position: absolute;
  z-index: 4;
  right: 25px;
  display: inline-flex;
  align-items: center;
  border-radius: 32px;
  padding: 2px 10px;
}

.review-chip span {
  color: var(--skyBlue-color);
  font-size: var(--font-14);
}

.white-chip {
  background: var(--backgroundWhite-color);
}

.orange-chip {
  background: var(--skyBlue-color);
}

.red-content {
  background-color: var(--chip-color);
  border-radius: 22px;
  padding: 3px 10px;
}

.cursor {
  cursor: pointer;
}

.review-chip .rating {
  background: var(--btnlight-yellow);
  padding: 3px 10px;
  border-radius: 22px;
}

.review-chip .rating span {
  font-size: var(--font-14) !important;
}

.review-chip .fav {
  cursor: pointer;
  padding: 0 10px;
}

.Jobs .currencyLabel select {
  color: var(--orange-color);
}

.rbt-menu.dropdown-menu.show {
  border-radius: 22px;
}

.Jobs .rbt-token {
  background-color: rgba(252, 147, 25, 0.1);
  color: var(--gray);
}

.Jobs .rbt-token .rbt-token-remove-button {
  right: -8px;
}

.Jobs .btn.btn-outline-secondary.circle {
  border-radius: 22px;
  color: var(--orange-color);
  border-color: var(--chip-color);
  background: var(--chip-color);
}

.footer-section-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small-text,
.small-text a {
  font-size: var(--font-12);
}

.plus-btn {
  position: absolute;
  right: 10px;
  top: 8px;
  background: rgba(24, 184, 252, 0.1);
  padding: 6px;
  border-radius: 22px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.custom-width {
  width: 25%;
  margin: auto;
}

img.small-profile {
  max-width: 45px;
  margin-right: 11px;
  margin-bottom: 12px;
}

.custom-alert .alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
  min-width: 42%;
  border-radius: 22px;
  padding: 20px 0;
}

.custom-alert .alert-head h2 {
  font-size: var(--font-36);
}

.custom-alert .alert-button-group .alert-button {
  text-transform: capitalize;
  border-radius: 22px;
}

.custom-alert .alert-button-group .alert-button.secondary {
  color: var(--skyBlue-color);
  border: 1.5px solid var(--skyBlue-color);
}

.custom-alert .alert-button-group .alert-button.secondary span {
  color: var(--skyBlue-color);
}

.custom-alert .alert-button-group .alert-button.warning {
  border: 1.5px solid var(--btn-yellow);
  background-color: var(--btn-yellow);
}

@media (max-width: 767px) {
  .Jobs .header-section {
    display: block;
  }

  .content-flex,
  .header-content-flex,
  .sort-input-section,
  .footer-section-flex {
    display: block;
  }

  .custom-width {
    width: 100%;
  }

  .search-input {
    padding: 0;
  }

  .filter-option .range-filter {
    padding: 10px 0;
    width: 100%;
  }

  .filter-option .sort-group {
    position: absolute;
    right: 4em;
    top: -8px;
  }

  .filter-option .sort-group.no-rightspace {
    right: 0em !important;
  }

  .filter-option .range-filter ion-range {
    padding: 0px;
  }
  ion-button ion-icon {
    font-size: 26px;
  }
  .filter-curser-icon {
    position: absolute;
    right: 0em;
    top: -14px;
  }
  .review-chip {
    top: 5px;
  }
  .toolbar-background {
    --border-style: none !important;
    background: var(--backgroundWhite-color);
  }

  ion-header.custom-header.ion-padding {
    padding: 0px;
  }
  ion-header.custom-header .title-default {
    padding: 10px;
  }
}
.round-button {
  height: 45px !important;
  margin: auto;
  --border-width: 1.8px;
  font-size: var(--font-10);
  --border-radius: 50%;
  /* --vertical-align: middle; */
  --padding-start: 10px;
  --padding-end: 10px;
}
.roundButton {
  width: 45px;
  height: 45px;
  --border-width: 1.8px;
  --border-radius: 50%;
  --vertical-align: middle;
  --padding-start: 10px;
  --padding-end: 10px;
  --background: var(--backgroundWhite-color);
  color: var(--gray);
  --border: 1px solid var(--lightGray);
}
ion-button ion-icon {
  font-size: 26px;
}
@media (max-width: 995px) {
  .ul-element li {
    width: 160px;
    display: initial;
    /* border: 0.05px solid var(--gray); */
    /* height: 34px; */
    padding: 15px;
  }
}

@media (min-width: 603px) and (max-width: 770px) {
  .locationInput .custom-groupInput-label-icon {
    top: 37px!important;
  }
}

@media (max-width: 435px) {
  .Jobs .flex-section {
    display: block;
  }

  .com-custom-grid {
    grid-template-columns: repeat(2, auto) !important;
  }

  .com-custom-grid .com-col-section {
    padding: 8px !important;
    border-right-style: none !important;
  }
}

@media (max-width: 990px) {
  .vertical-line {
    border-right: 0px solid var(--lightGray);
    border-bottom: 1px solid var(--lightGray);
  }

  .ul-element {
    padding-left: 0;
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
  }
}

.locationInput .custom-groupInput-label-icon {
  right: 3px;
  top: 41px;
}
@media (max-width: 600px) {
  .locationInput .custom-groupInput-label-icon {
    top: 37px !important;
  }
}

/* company profile */

.company-location input {
  width: 100%;
  height: 44px;
  border-radius: 50px;
  border: 1px solid;
}

td {
  white-space: normal !important;
  word-wrap: break-word;
}
table .table-header {
  display: flex;
  justify-content: space-between;
}

.canditate .locationSearch {
  top: 0px !important;
}

@media (max-width: 787px) {
  /* table {
    border: 0;
  } */

  /* table thead,
  table tr th {
    display: none;
  } */

  /* table tr {
    margin-bottom: 10px;
    display: block;
    border-bottom: 2px solid #ddd;
  } */

  /* table tr ion-button {
    padding: 0;
    margin: 0;
  } */
  /* 
  table td {
    padding: 0 0.5rem !important;
    display: block;
    text-align: left;
    font-size: 13px;
    border-bottom: 1px dotted #ccc;
  } */

  /* table td:last-child {
    border-bottom: 0;
  }

  table td:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 20px;
  } */
}

.custom-position {
  position: absolute;
  top: 29px;
  right: 31px;
  z-index: 4;
}

.table-light {
  --bs-table-bg: var(--backgroundDark);
  border-color: var(--lightGray);
  color: var(--textBlack-color);
}

.table td,
.table {
  color: var(--textBlack-color);
  border-color: var(--lightGray);
  border-bottom: 1px solid var(--lightGray);
}
.react-datepicker,
.react-datepicker__header {
  background-color: var(--backgroundDark);
  color: var(--textBlack-color);
  border-bottom: 1px solid var(--border-gray);
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--textBlack-color);
}
.nav-tabs {
  border-color: var(--lightGray);
}
.select-block ion-item,
.custom-select,
.form-select {
  --background: var(--backgroundDark);
  color: var(--textBlack-color);
  border: 1px solid var(--lightGray);
  background-color: var(--backgroundDark);
}

.ck .ck-editor__main > .ck-editor__editable,
.ck.ck-icon,
.ck.ck-button,
.ck.ck-toolbar > .ck-toolbar__items {
  background: var(--ion-background-color);
  border-color: var(--lightGray);
  --ck-color-toolbar-background: var(--backgroundDark);
  --ck-color-base-background: var(--backgroundDark);
  --ck-color-base-border: var(--lightGray);
  --ck-color-text: var(--textBlack-color);
  color: var(--textBlack-color);
}

.sort-field ion-label {
  flex: none !important;
  border: solid 1px var(--lightGray);
  --color: var(--gray);
  padding: 0.495rem 0.75rem;
  --background: var(--backgroundWhite-color);
  border-radius: 22px;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  -ms-border-radius: 22px;
  -o-border-radius: 22px;
}

.sort-field ion-select {
  --background: var(--backgroundDark);
  color: var(--textBlack-color);
  border: 1px solid var(--lightGray);
  background-color: var(--backgroundDark);
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.domain-add-btn ion-button {
  width: 38px;
  height: 38px;
  position: absolute;
  top: 23px;
  right: 4px;
}

.custom-position-1 {
  position: absolute;
  top: 3px;
  right: 31px;
  z-index: 4;
}

.domain-add-btn ion-button ion-icon {
  font-size: 24px;
}

.engagement ion-range {
  /* margin-top: 10px; */
  padding: inherit;
}

.custom-select-fit{
  max-width: fit-content;
  padding: 3px 10px !important;
}

.custom-align {
align-items: last baseline;
}
