/* drag and drop */
.qp-upload #drop-area {
    position: relative;
    width: 70%;
    margin: auto;
}

.qp-upload #fileElem {
    display: none;
}

.qp-upload .upload-label {
    margin: 30px 0;
    padding: 10px;
    cursor: pointer;
    border-radius: 22px;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    border: dashed var(--skyBlue-color);

    height: 335px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(24, 184, 252, 0.1);
}

.qp-upload .upload-label:hover {
    background-color: rgba(24, 184, 252, 0.1);
}

.qp-upload .upload-label:active {
    background-color: rgba(24, 184, 252, 0.1);
}

.qp-upload .upload-text {
    color: #666;
    user-select: none;
}

.qp-upload .upload-text h5 {
    font-size: var(--font-20);
    padding-bottom: 8px;
}

.qp-upload .upload-text p {
    color: var(--gray-color)
}

.qp-upload .upload-text img {
    width: 72px;
    height: 72px;
    margin: 0 93px 16px;
}

.qp-upload .image {
    min-width: 100%;
    max-width: 100%;
    height: 140px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}


/* upload */

.qp-upload .form-content h1 {
    font-size: var(--font-32);
}

.qp-upload .bottom-section .footer-content small {
    font-size: var(--font-12);
}

.qp-upload .bottom-section img {
    width: 255px;
    padding-bottom: 30px;
}

/*  */

.qprofile .swiper-pagination {
    top: 10px;
    height: 1em;
    bottom: auto;
}

.qp-upload .form-content {
    /* padding: 40px 0; */
    padding-top: 5em;
    padding-bottom: 20px;
}

.qp-upload .form-footer {
    margin-top: 20px;
    text-align: right;
    z-index: 10299;
}

.qp-upload .custom-button {
    width: 128px;
    height: 44px;
    padding: 13px 40px 12px;
    border-radius: 22px;
    background-color: var(--orange-color);
    color: #fff;
}

.qp-upload h3.header-h3 {
    padding: 20px 0;
    font-size: var(--font-28);
    font-family: var(--font-bold);
}

.qp-upload .custom-sub-header {
    padding: 0 8px;
}

.qp-upload .archor {
    padding-top: 0px;
}


.qp-upload .custom-sub-header ion-card-title {
    font-size: 22px;
}

/* division line */

.divider-line {
    height: 1px;
    background: var(--lightGray);
    margin: 30px 0;
}

.divider-Innerline {
    height: 1px;
    background: var(--lightGray);
    margin: 30px 0;

}


/* slider */

.qp-upload .slide-main {
    width: 100%;
}

@media (max-width: 768px) {
    .qp-upload #drop-area {
        position: relative;
        width: 100%;
    }
}