/* cover image */
.wrapper {
  display: flex;
  justify-content: space-between;
}

.cover-img {
  background-color: var(--background-img-gray);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 240px;
  z-index: 0;
  border-radius: 22px;
}

button.ant-btn.ant-btn-circle.ant-btn-icon-only.icon-btn.custom-position.cover-edit {
  right: 15px;
  top: 10px;
  background-color: rgba(255, 255, 255, 0.72);
}

.left-slider {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  width: 30%;
  z-index: 5;
  height: 100%;
  min-height: 200px;
  position: -webkit-sticky;
  position: sticky;
  top: 15%;
  display: block;
}

.right-content {
  width: 70%;
  display: flex;
  padding: 0 32px;
  padding-right: 0;
  flex-direction: column;
}


.left-slider .photo-left {
  z-index: 555;
  text-align: center;
  position: relative;
  margin-bottom: -40px;
}

.left-slider ion-card {
  margin: 0;
  z-index: 0;
}


/* personal */



.btn.btn-default.cover-img {
  position: absolute;
  right: 20px;
  top: 20px;
  background: #fff;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  padding: 12px;
}

@media (max-width: 768px) {
  .left-slider {
    display: none;
    visibility: hidden;
  }

  .right-content {
    padding: 0px;
    width: 100%;
  }
}