.input-block {
    position: relative;
    display: grid;
    width: 100%;
    /* margin-bottom: 5px; */
    z-index: 0;
    /* text-align: left; */
}

.input-block+.input-block {
    margin-top: 0.9rem;
}

.input-block label {

    font-size: var(--font-14);
    /* margin-bottom: 12px; */
    color: var(--textBlack-color);
    margin: 0;
    padding-top: 10px;
    text-transform: capitalize;
}

.input-block input {
    /* height: 48px; */
    width: 100%;
    /* margin-top: 0.48rem; */
    border-radius: 50px;
    font-style: italic;
    padding: 11px 15px;
    outline: none;
    line-height: 1.5;
    border: 1px solid var(--lightGray);
    font-size: var(--font-16);
    color: var(--textBlack-color);
}

/* .input-block:focus-within::after {
    width: calc(100% - 0.96rem);
    height: 2px;
    content: '';
    background: var(--color-primary-light);
    position: absolute;
    left: 0.48rem;
    right: 0.48rem;
    bottom: 0;
} */

.input-checkbox {
    display: inherit;
    margin-bottom: 34px;
}

.input-checkbox input[type=checkbox],
input[type=radio] {
    width: 18px;
    height: 18px;
}

.input-block input[type=number] {
    appearance: none;
    color: var(--textBlack-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.input-checkbox input[type="checkbox"]:checked {
    background: var(--backgroundDark) !important;
    border: 1px solid var(--lightGray);
}

.input-checkbox label {
    margin-left: 12px;
    font-size: var(--font-16);
    /* font-weight: bold; */
    margin-bottom: 12px;
    margin: 0;
    padding-top: 10px;
    text-transform: capitalize;
}


/* textarea */

.textarea-block {
    position: relative;
    margin-top: 0.9rem;
    display: grid;
    width: 100%;
    margin-bottom: 5px;
}

.textarea-block label {
    font-size: var(--font-14);
    /* margin-bottom: 12px; */
    color: var(--textBlack-color);
    text-transform: capitalize;
}

.textarea-block textarea {
    width: 100%;
    height: 200px;
    /* margin-top: 0.48rem; */
    border-radius: 24px;
    font-style: italic;
    padding: 18px;
    outline: none;
    line-height: 1.5;
    border: 1px solid var(--lightGray);
    font-size: var(--font-16);
    color: var(--textBlack-color);
}

.input-block ion-item {
    --background: #fff;
}

/* .textarea-block:focus-within::after {
    width: calc(100% - 0.96rem);
    height: 2px;
    content: '';
    background: var(--color-primary-light);
    position: absolute;
    left: 0.48rem;
    right: 0.48rem;
    bottom: 3px;
} */