:root {
  --hirifycomnero: rgba(255, 255, 255, 1);
  --hirifycomporcelain: rgba(242, 243, 244, 1);
  --hirifycomblack: rgba(0, 0, 0, 1);
  --hirifycommidnight: rgba(0, 17, 44, 1);
  --hirifycombiscay: rgba(20, 56, 96, 1);
  --hirifycomwhite-ice: rgba(232, 247, 251, 1);
  --hirifycomathens-gray: rgba(226, 229, 234, 1);
  --hirifycomeastern-blue: rgba(23, 157, 186, 1);
  --hirifycomshamrock: rgba(55, 197, 151, 1);
  --hirifycombali-hai: rgba(135, 149, 170, 1);
  --hirifycomturquoise: rgba(56, 232, 176, 1);
  --hirifycomjava: rgba(26, 175, 208, 1);
  --hirificomnero: rgba(255, 255, 255, 1);
  --hirificomcod-gray: rgba(27, 27, 27, 1);
  --hirificomwild-sand: rgba(245, 245, 245, 1);
  --hirificomgolden-sand: rgba(242, 223, 126, 1);
  --hirificomblack: rgba(0, 0, 0, 1);
  --hirificomlimed-oak: rgba(163, 149, 75, 1);
  --hirificommercury: rgba(230, 230, 230, 1);
  --hirificomtacha: rgba(212, 195, 109, 1);
  --hirificomarmadillo: rgba(70, 67, 58, 1);
  --hirificommine-shaft: rgba(42, 42, 42, 1);
  --hirificomsilver-chalice: rgba(169, 169, 169, 1);
  --hirificomscorpion: rgba(89, 89, 89, 1);
  --hirificomyellow-metal: rgba(130, 119, 61, 1);
  --hirificomfiord: rgba(69, 90, 100, 1);
  --hirificomwestar: rgba(229, 227, 223, 1);
  --hirificomdove-gray: rgba(102, 102, 102, 1);
  --hirificomcornflower-blue: rgba(66, 133, 244, 1);
  --hirificomcinnabar: rgba(233, 66, 53, 1);
  --hirificomselective-yellow: rgba(250, 187, 5, 1);
  --hirificomchateau-green: rgba(52, 168, 83, 1);
  --hirificomalto: rgba(219, 219, 219, 1);
  --hirificomlog-cabin: rgba(25, 25, 23, 1);
  --hirify-com-segoe-UI-regular-32-font-family: "Segoe UI", Helvetica;
  --hirify-com-segoe-UI-regular-32-font-weight: 400;
  --hirify-com-segoe-UI-regular-32-font-size: 32px;
  --hirify-com-segoe-UI-regular-32-letter-spacing: 0.3499999940395355px;
  --hirify-com-segoe-UI-regular-32-line-height: 24px;
  --hirify-com-segoe-UI-regular-32-font-style: normal;
  --hirify-com-segoe-UI-bold-56-font-family: "Segoe UI", Helvetica;
  --hirify-com-segoe-UI-bold-56-font-weight: 700;
  --hirify-com-segoe-UI-bold-56-font-size: 56px;
  --hirify-com-segoe-UI-bold-56-letter-spacing: 1px;
  --hirify-com-segoe-UI-bold-56-line-height: 72px;
  --hirify-com-segoe-UI-bold-56-font-style: normal;
  --hirify-com-segoe-UI-bold-24-font-family: "Segoe UI", Helvetica;
  --hirify-com-segoe-UI-bold-24-font-weight: 700;
  --hirify-com-segoe-UI-bold-24-font-size: 24px;
  --hirify-com-segoe-UI-bold-24-letter-spacing: 0.5px;
  --hirify-com-segoe-UI-bold-24-line-height: 32px;
  --hirify-com-segoe-UI-bold-24-font-style: normal;
  --hirify-com-segoe-UI-regular-16-font-family: "Segoe UI", Helvetica;
  --hirify-com-segoe-UI-regular-16-font-weight: 400;
  --hirify-com-segoe-UI-regular-16-font-size: 16px;
  --hirify-com-segoe-UI-regular-16-letter-spacing: 0.3499999940395355px;
  --hirify-com-segoe-UI-regular-16-line-height: 24px;
  --hirify-com-segoe-UI-regular-16-font-style: normal;
  --hirify-com-segoe-UI-bold-18-font-family: "Segoe UI", Helvetica;
  --hirify-com-segoe-UI-bold-18-font-weight: 700;
  --hirify-com-segoe-UI-bold-18-font-size: 18px;
  --hirify-com-segoe-UI-bold-18-letter-spacing: 0.5px;
  --hirify-com-segoe-UI-bold-18-line-height: 24px;
  --hirify-com-segoe-UI-bold-18-font-style: normal;
  --hirify-com-segoe-UI-regular-12-font-family: "Segoe UI", Helvetica;
  --hirify-com-segoe-UI-regular-12-font-weight: 400;
  --hirify-com-segoe-UI-regular-12-font-size: 12px;
  --hirify-com-segoe-UI-regular-12-letter-spacing: 0.4000000059604645px;
  --hirify-com-segoe-UI-regular-12-line-height: 16px;
  --hirify-com-segoe-UI-regular-12-font-style: normal;
  --hirify-com-segoe-UI-bold-16-font-family: "Segoe UI", Helvetica;
  --hirify-com-segoe-UI-bold-16-font-weight: 700;
  --hirify-com-segoe-UI-bold-16-font-size: 16px;
  --hirify-com-segoe-UI-bold-16-letter-spacing: 0.3499999940395355px;
  --hirify-com-segoe-UI-bold-16-line-height: 16px;
  --hirify-com-segoe-UI-bold-16-font-style: normal;
  --hirify-com-segoe-UI-semibold-18-font-family: "Segoe UI", Helvetica;
  --hirify-com-segoe-UI-semibold-18-font-weight: 400;
  --hirify-com-segoe-UI-semibold-18-font-size: 18px;
  --hirify-com-segoe-UI-semibold-18-letter-spacing: 0.3499999940395355px;
  --hirify-com-segoe-UI-semibold-18-line-height: 24px;
  --hirify-com-segoe-UI-semibold-18-font-style: normal;
  --hirify-com-segoe-UI-semibold-16-font-family: "Segoe UI", Helvetica;
  --hirify-com-segoe-UI-semibold-16-font-weight: 400;
  --hirify-com-segoe-UI-semibold-16-font-size: 16px;
  --hirify-com-segoe-UI-semibold-16-letter-spacing: 0.3499999940395355px;
  --hirify-com-segoe-UI-semibold-16-line-height: 16px;
  --hirify-com-segoe-UI-semibold-16-font-style: normal;
  --hirifi-com-poppins-black-62-font-family: "Poppins", Helvetica;
  --hirifi-com-poppins-black-62-font-weight: 700;
  --hirifi-com-poppins-black-62-font-size: 62px;
  --hirifi-com-poppins-black-62-letter-spacing: 0px;
  --hirifi-com-poppins-black-62-line-height: 86.80000305175781px;
  --hirifi-com-poppins-black-62-font-style: normal;
  --hirifi-com-poppins-black-44-font-family: "Poppins", Helvetica;
  --hirifi-com-poppins-black-44-font-weight: 700;
  --hirifi-com-poppins-black-44-font-size: 44px;
  --hirifi-com-poppins-black-44-letter-spacing: 0px;
  --hirifi-com-poppins-black-44-line-height: 52.79999923706055px;
  --hirifi-com-poppins-black-44-font-style: normal;
  --hirifi-com-poppins-black-22-font-family: "Poppins", Helvetica;
  --hirifi-com-poppins-black-22-font-weight: 700;
  --hirifi-com-poppins-black-22-font-size: 22px;
  --hirifi-com-poppins-black-22-letter-spacing: 0px;
  --hirifi-com-poppins-black-22-line-height: 27.5px;
  --hirifi-com-poppins-black-22-font-style: normal;
  --hirifi-com-noto-sans-display-regular-25-font-family: "Noto Sans", Helvetica;
  --hirifi-com-noto-sans-display-regular-25-font-weight: 400;
  --hirifi-com-noto-sans-display-regular-25-font-size: 25px;
  --hirifi-com-noto-sans-display-regular-25-letter-spacing: 0px;
  --hirifi-com-noto-sans-display-regular-25-line-height: 37.5px;
  --hirifi-com-noto-sans-display-regular-25-font-style: normal;
  --hirifi-com-noto-sans-bold-14-upper-font-family: "Noto Sans", Helvetica;
  --hirifi-com-noto-sans-bold-14-upper-font-weight: 700;
  --hirifi-com-noto-sans-bold-14-upper-font-size: 14px;
  --hirifi-com-noto-sans-bold-14-upper-letter-spacing: 0.9940000176429749px;
  --hirifi-com-noto-sans-bold-14-upper-line-height: normal;
  --hirifi-com-noto-sans-bold-14-upper-font-style: normal;
  --hirifi-com-noto-sans-bold-14-font-family: "Noto Sans", Helvetica;
  --hirifi-com-noto-sans-bold-14-font-weight: 700;
  --hirifi-com-noto-sans-bold-14-font-size: 14px;
  --hirifi-com-noto-sans-bold-14-letter-spacing: 0px;
  --hirifi-com-noto-sans-bold-14-line-height: 21px;
  --hirifi-com-noto-sans-bold-14-font-style: normal;
  --hirifi-com-noto-sans-display-regular-14-font-family: "Noto Sans", Helvetica;
  --hirifi-com-noto-sans-display-regular-14-font-weight: 400;
  --hirifi-com-noto-sans-display-regular-14-font-size: 14px;
  --hirifi-com-noto-sans-display-regular-14-letter-spacing: 0px;
  --hirifi-com-noto-sans-display-regular-14-line-height: normal;
  --hirifi-com-noto-sans-display-regular-14-font-style: normal;
  --hirifi-com-noto-sans-bold-13-font-family: "Noto Sans", Helvetica;
  --hirifi-com-noto-sans-bold-13-font-weight: 700;
  --hirifi-com-noto-sans-bold-13-font-size: 13px;
  --hirifi-com-noto-sans-bold-13-letter-spacing: 0px;
  --hirifi-com-noto-sans-bold-13-line-height: 19.5px;
  --hirifi-com-noto-sans-bold-13-font-style: normal;
  --hirifi-com-noto-sans-display-regular-13-font-family: "Noto Sans", Helvetica;
  --hirifi-com-noto-sans-display-regular-13-font-weight: 400;
  --hirifi-com-noto-sans-display-regular-13-font-size: 13px;
  --hirifi-com-noto-sans-display-regular-13-letter-spacing: 0px;
  --hirifi-com-noto-sans-display-regular-13-line-height: 19.5px;
  --hirifi-com-noto-sans-display-regular-13-font-style: normal;
  --hirifi-com-noto-sans-display-regular-16-font-family: "Noto Sans", Helvetica;
  --hirifi-com-noto-sans-display-regular-16-font-weight: 400;
  --hirifi-com-noto-sans-display-regular-16-font-size: 16px;
  --hirifi-com-noto-sans-display-regular-16-letter-spacing: 0px;
  --hirifi-com-noto-sans-display-regular-16-line-height: normal;
  --hirifi-com-noto-sans-display-regular-16-font-style: normal;
  --hirifi-com-noto-sans-display-regular-22-font-family: "Noto Sans", Helvetica;
  --hirifi-com-noto-sans-display-regular-22-font-weight: 400;
  --hirifi-com-noto-sans-display-regular-22-font-size: 22px;
  --hirifi-com-noto-sans-display-regular-22-letter-spacing: 0px;
  --hirifi-com-noto-sans-display-regular-22-line-height: 24.75px;
  --hirifi-com-noto-sans-display-regular-22-font-style: normal;
  --hirifi-com-noto-sans-bold-12-upper-font-family: "Noto Sans", Helvetica;
  --hirifi-com-noto-sans-bold-12-upper-font-weight: 700;
  --hirifi-com-noto-sans-bold-12-upper-font-size: 12px;
  --hirifi-com-noto-sans-bold-12-upper-letter-spacing: 0.8519999980926514px;
  --hirifi-com-noto-sans-bold-12-upper-line-height: normal;
  --hirifi-com-noto-sans-bold-12-upper-font-style: normal;
  --hirifi-com-noto-sans-display-regular-20-font-family: "Noto Sans", Helvetica;
  --hirifi-com-noto-sans-display-regular-20-font-weight: 400;
  --hirifi-com-noto-sans-display-regular-20-font-size: 20px;
  --hirifi-com-noto-sans-display-regular-20-letter-spacing: 0px;
  --hirifi-com-noto-sans-display-regular-20-line-height: 30px;
  --hirifi-com-noto-sans-display-regular-20-font-style: normal;
  --hirifi-com-noto-sans-bold-20-font-family: "Noto Sans", Helvetica;
  --hirifi-com-noto-sans-bold-20-font-weight: 700;
  --hirifi-com-noto-sans-bold-20-font-size: 20px;
  --hirifi-com-noto-sans-bold-20-letter-spacing: 0px;
  --hirifi-com-noto-sans-bold-20-line-height: 30px;
  --hirifi-com-noto-sans-bold-20-font-style: normal;
  --hirifi-com-liberation-sans-bold-16-font-family: "Liberation Sans", Helvetica;
  --hirifi-com-liberation-sans-bold-16-font-weight: 700;
  --hirifi-com-liberation-sans-bold-16-font-size: 16px;
  --hirifi-com-liberation-sans-bold-16-letter-spacing: 0px;
  --hirifi-com-liberation-sans-bold-16-line-height: normal;
  --hirifi-com-liberation-sans-bold-16-font-style: normal;
  --hirifi-com-roboto-regular-10-font-family: "Roboto", Helvetica;
  --hirifi-com-roboto-regular-10-font-weight: 400;
  --hirifi-com-roboto-regular-10-font-size: 10px;
  --hirifi-com-roboto-regular-10-letter-spacing: 0px;
  --hirifi-com-roboto-regular-10-line-height: 14px;
  --hirifi-com-roboto-regular-10-font-style: normal;
}
