.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 42px;
  padding: 0 8px;
  border: 1px solid var(--border-gray);
  border-radius: 22px;
}
.tags-input:focus-within {
  border: 1px solid var(--light-skyblue);
}
.tags-input input {
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
}
.tags-input input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 4px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--textWhite-color);
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 22px;
  margin: 0 2px 8px 0;
  background: var(--light-skyblue);
}
.tag .tag-title {
  margin-top: 3px;
}
.tag .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 16px;
  margin-left: 8px;  
  color: var(--skyBlue-color);
  border-radius: 50%;
  cursor: pointer;
}

.tag-dropdown {
  position: relative;
}

.tag-dropdown .dropdown-content {
  position: absolute;
  top: 100%;
  padding: 8px;
  z-index: 5;
  border-radius: 22px;
  list-style-type: none;
  background: var(--backgroundWhite-color);
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid var(--border-gray);
}
.tag-dropdown .dropdown-content li {
  padding: 9px;
}
.tag-dropdown .dropdown-content li:hover {
  background: var(--border-gray);
}

@media screen and (max-width: 567px) {
  .tags-input {
    /* width: calc(100vw - 32px); */
  }
}
