.custome-timeline ul.timeline {
    list-style-type: none;
    position: relative;
    margin-left: -22px;
}

.custome-timeline ul.timeline:before {
    content: ' ';
    background: var(--orange-color);
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: calc(100% - 100px);
    z-index: 400;
}

.custome-timeline ul.timeline {
    margin-top: 20px;
}

.custome-timeline ul.timeline>li {
    /* margin: 20px 0; */
    padding-left: 20px;
}

.custome-timeline ul.timeline>li:before {
    content: ' ';
    background: var(--orange-color);
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 1px solid var(--orange-color);
    left: 25px;
    width: 10px;
    height: 10px;
    z-index: 400;
}