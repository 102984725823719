.custum-range-slide ion-range {
  z-index: 0;
  --bar-background: var(--lightGray);
  /* --bar-background-active: linear-gradient(to right, #ff5353, #ffd60c, #2fd300); */
  --bar-height: 6px;
  --bar-border-radius: 5px;
  /* --knob-background: var(--textWhite-color); */
  --pin-color: var(--textWhite-color);
  --knob-size: 30px;
}

.custum-range-slide ion-range.range-knob-hide.range-disabled {
  --bar-background: transparent;

  --knob-size: 0px;
  --pin-color: var(--textWhite-color);
  --knob-background: var(--textWhite-color);
}

.custum-range-slide ion-range.range-knob-hide, :host(.range-disabled) .range-bar  {
  --ion-color-step-250: transparent;
}

.custum-range-slide ion-range.poor {
  --bar-background-active: linear-gradient(
    to right,
    var(--slide-poor),
    var(--slide-poor),
    var(--slide-poor)
  );
  --knob-background: var(--slide-poor);
}

.custum-range-slide ion-range.average {
  --bar-background-active: linear-gradient(
    to right,
    var(--slide-poor),
    var(--slide-average),
    var(--slide-average)
  );
  --knob-background: var(--slide-average);
}

.custum-range-slide ion-range.good {
  --bar-background-active: linear-gradient(
    to right,
    var(--slide-poor),
    var(--slide-average),
    var(--slide-good)
  );
  --knob-background: var(--slide-good);
}

/* background: linear-gradient(to right, #ff5353, #ffd60c, #2fd300); */
